import CustomPagination from "components/Common/CustomPagination";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  // SaleOrderComment
  getPagingSaleOrderComment,
  addSaleOrderComment,
  // getSaleOrderComments,
  getSaleOrderCommentById,
  updateSaleOrderComment,
  deleteSaleOrderComment,
  // clearSaleOrderCommentError,
} from "store/actions";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Table,
} from "reactstrap";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import { toastMessage, timestampFileName } from "helpers/utils";
import NumberFormat from "react-number-format"
import SaleOrderCommentCard from "../../containers/sale-order-comment/SaleOrderCommentCard";
import ModalForm from "containers/sale-order-comment/ModalForm"
import SaleOrderCommentTable from "./SaleOrderCommentTable";

const SaleOrderCommentTab = props => {
  const [ selectedId, setSelectedId ] = useState(null);
  const [ currentPage, setpage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);

  const [ modalAddIsOpen, setModalAddIsOpen ] = useState(false);
  const [ modalEditIsOpen, setModalEditIsOpen ] = useState(false);
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false);
  const [modalRemoveImage, setModalRemoveImage] = useState(false);
  const {
    getPagingSaleOrderComment,
    addSaleOrderComment,
    getSaleOrderCommentById,
    updateSaleOrderComment,
    deleteSaleOrderComment,
    clearSaleOrderCommentError,
    saleOrderComment,

    saleOrderId,
  } = props;

  const {
    items,
    item,
    totalAmount,
    message,
    itemCount,
    pageCount,
    loading,
  } = saleOrderComment;

  useEffect(() => {
    getPagingSaleOrderComment({ saleOrderId });
  }, []);

  const handleSubmit = data => {

    addSaleOrderComment({
      data,
      queryParams: {
        saleOrderId
      }
    });

    setModalAddIsOpen(false);
  };

  const handleDownload = (item) => {
    return item.url;
  };

  const handleOnEdit = (id) => {
    getSaleOrderCommentById(id);
    setModalEditIsOpen(true);
  }

  const handleUpdate = data => {
    updateSaleOrderComment({
      data,
      queryParams: {
        saleOrderId
      }
    });

    setModalEditIsOpen(false);
  };

  const handleOnPageSizeChange = size => {
    const { saleOrderId, getPagingSaleOrderComment } = this.props;
    getPagingSaleOrderComment({ saleOrderId, page: 1, pageSize: size });
    this.setState({ pageSize: size });
  };
  
  const handleOnChangePage = page => {
    const { saleOrderId, pageSize } = this.state;
    this.props.getPagingSaleOrderComment({ saleOrderId, page, pageSize });
    this.setState({
      currentPage: page,
    });
  };

  const handleRemoveImage = id => {
    setSelectedId(id);
    setModalRemoveImage(true);
  };
  

  const toggleRemoveImage = () => {
    setModalRemoveImage(prevState => !prevState);
  };

  const handleSubmitRemoveImage = () => {
  
    setModalRemoveImage(false); 
  
    deleteSaleOrderComment({ 
      id: selectedId, 
      saleOrderId
    });
  };
  
  return (
      <div>
        <Card className="mb-1">
          <CardHeader className="bg-transparent border-bottom">
            <Row className="g-1">
              <Col md={ 2 }>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                        type="text"
                        placeholder="Quick Search ..."
                        onChange={ e => {
                          const { value } = e.target;
                          //   debouncedQuickSearch(value);
                        } }
                    ></Input>
                    <i className="fas fa-search search-icon"/>
                  </div>
                </div>
              </Col>
              <Col md={ 10 }>
                <div className="text-sm-end">
                  <Button
                      type="button"
                      color="primary"
                      className="me-1"
                      onClick={ () => setModalAddIsOpen(true) }
                  >
                    <i className="fas fa-plus"/> Add New
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        {
          loading ?
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div> :
              <>
                {
                  items.map((item, index) => {
                    return <SaleOrderCommentCard
                        key={ index }
                        item={ item }
                        onDownload={handleDownload}
                        onEdit={ handleOnEdit }
                        //onConfirmDelete={ handleOnConfirmDelete }
                        />
                  })
                }

                </>

        }
         {items && (
                  <SaleOrderCommentTable
                   items={items}
                    itemCount={itemCount}
                    currentPage={currentPage}
                    totalPage={pageCount}
                    loading={loading}
                    defaultPageSize={pageSize}
                    onConfirmDelete={handleRemoveImage}
                    onChangePage={handleOnChangePage}
                    onPageSizeChange={handleOnPageSizeChange}
                  />
                )} 
        <ModalForm
            title="Add Comment"
            saleOrderId={ saleOrderId }
            isOpen={ modalAddIsOpen }
            toggle={ () => setModalAddIsOpen(!modalAddIsOpen) }
            onSubmit={ handleSubmit }
        />
        { item ? (
            <ModalForm
                title="Edit Comment"
                item={ item }
                saleOrderId={ saleOrderId }
                isOpen={ modalEditIsOpen }
                toggle={ () => setModalEditIsOpen(!modalEditIsOpen) }
                onSubmit={ handleUpdate }
            />
        ) : null }

          <ConfirmDeleteModal
            title="Confirm Delete"
            isOpen={modalRemoveImage}
            toggle={toggleRemoveImage}
            onSubmit={handleSubmitRemoveImage}
          />
      </div>
  );
};


SaleOrderCommentTab.propTypes = {
  saleOrderId: PropTypes.string,
  message: PropTypes.object,
  saleOrderComment: PropTypes.object,
  getPagingSaleOrderComment: PropTypes.func,
  addSaleOrderComment: PropTypes.func,
  getSaleOrderCommentById: PropTypes.func,
  updateSaleOrderComment: PropTypes.func,
  deleteSaleOrderComment: PropTypes.func,
  clearSaleOrderCommentError: PropTypes.func,
};

const mapStateToProps = ({ saleOrderComment }) => {
  return {
    saleOrderComment
  };
};

export default withRouter(
    connect(mapStateToProps, {
      getPagingSaleOrderComment,
      addSaleOrderComment,
      getSaleOrderCommentById,
      updateSaleOrderComment,
      deleteSaleOrderComment,
      // clearSaleOrderCommentError,
    })(SaleOrderCommentTab)
);
