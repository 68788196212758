import {
  DISCOUNT_PROMOTION_ADD_NEW,
  DISCOUNT_PROMOTION_ADD_NEW_ERROR,
  DISCOUNT_PROMOTION_ADD_NEW_SUCCESS,
  DISCOUNT_PROMOTION_DELETE,
  DISCOUNT_PROMOTION_DELETE_ERROR,
  DISCOUNT_PROMOTION_DELETE_SUCCESS,
  DISCOUNT_PROMOTION_GET_BY_ID,
  DISCOUNT_PROMOTION_GET_BY_ID_ERROR,
  DISCOUNT_PROMOTION_GET_BY_ID_SUCCESS,
  DISCOUNT_PROMOTION_GET_PAGING,
  DISCOUNT_PROMOTION_GET_PAGING_ERROR,
  DISCOUNT_PROMOTION_GET_PAGING_SUCCESS,
  DISCOUNT_PROMOTION_UPDATE,
  DISCOUNT_PROMOTION_UPDATE_ERROR,
  DISCOUNT_PROMOTION_UPDATE_SUCCESS,
  DISCOUNT_PROMOTION_SUCCESS,
  DISCOUNT_PROMOTION_ERROR,
  DISCOUNT_PROMOTION
} from './actionType';

// DiscountPromotion
export const getPagingDiscountPromotion = items => ({
  type: DISCOUNT_PROMOTION_GET_PAGING,
  payload: items,
});

export const getPagingDiscountPromotionSuccess = items => ({
  type: DISCOUNT_PROMOTION_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingDiscountPromotionError = error => ({
  type: DISCOUNT_PROMOTION_GET_PAGING_ERROR,
  payload: error,
});

export const addDiscountPromotion = items => ({
  type: DISCOUNT_PROMOTION_ADD_NEW,
  payload: items,
});

export const addDiscountPromotionSuccess = items => ({
  type: DISCOUNT_PROMOTION_ADD_NEW_SUCCESS,
  payload: items,
});

export const addDiscountPromotionError = error => ({
  type: DISCOUNT_PROMOTION_ADD_NEW_ERROR,
  payload: error,
});

export const getDiscountPromotionById = items => ({
  type: DISCOUNT_PROMOTION_GET_BY_ID,
  payload: items,
});

export const getDiscountPromotionByIdSuccess = items => ({
  type: DISCOUNT_PROMOTION_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getDiscountPromotionByIdError = error => ({
  type: DISCOUNT_PROMOTION_GET_BY_ID_ERROR,
  payload: error,
});

export const updateDiscountPromotion = items => ({
  type: DISCOUNT_PROMOTION_UPDATE,
  payload: items,
});

export const updateDiscountPromotionSuccess = items => ({
  type: DISCOUNT_PROMOTION_UPDATE_SUCCESS,
  payload: items,
});

export const updateDiscountPromotionError = error => ({
  type: DISCOUNT_PROMOTION_UPDATE_ERROR,
  payload: error,
});

export const deleteDiscountPromotion = items => ({
  type: DISCOUNT_PROMOTION_DELETE,
  payload: items,
});

export const deleteDiscountPromotionSuccess = items => ({
  type: DISCOUNT_PROMOTION_DELETE_SUCCESS,
  payload: items,
});

export const deleteDiscountPromotionError = error => ({
  type: DISCOUNT_PROMOTION_DELETE_ERROR,
  payload: error,
});

export const getDiscountPromotion = items => ({
  type: DISCOUNT_PROMOTION,
  payload: items,
});


export const getDiscountPromotionSuccess = items => ({
  type: DISCOUNT_PROMOTION_SUCCESS,
  payload: items,
});

export const getDiscountPromotionError = error => ({
  type: DISCOUNT_PROMOTION_ERROR,
  payload: error,
});
