// DISCOUNT_PROMOTION
export const DISCOUNT_PROMOTION_ADD_NEW = 'DISCOUNT_PROMOTION_ADD_NEW';
export const DISCOUNT_PROMOTION_ADD_NEW_SUCCESS = 'DISCOUNT_PROMOTION_ADD_NEW_SUCCESS';
export const DISCOUNT_PROMOTION_ADD_NEW_ERROR = 'DISCOUNT_PROMOTION_ADD_NEW_ERROR';

export const DISCOUNT_PROMOTION_GET_PAGING = 'DISCOUNT_PROMOTION_GET_PAGING';
export const DISCOUNT_PROMOTION_GET_PAGING_SUCCESS = 'DISCOUNT_PROMOTION_GET_PAGING_SUCCESS';
export const DISCOUNT_PROMOTION_GET_PAGING_ERROR = 'DISCOUNT_PROMOTION_GET_PAGING_ERROR';

export const DISCOUNT_PROMOTION_GET_BY_ID = 'DISCOUNT_PROMOTION_GET_BY_ID';
export const DISCOUNT_PROMOTION_GET_BY_ID_SUCCESS = 'DISCOUNT_PROMOTION_GET_BY_ID_SUCCESS';
export const DISCOUNT_PROMOTION_GET_BY_ID_ERROR = 'DISCOUNT_PROMOTION_GET_BY_ID_ERROR';

export const DISCOUNT_PROMOTION_UPDATE = 'DISCOUNT_PROMOTION_UPDATE';
export const DISCOUNT_PROMOTION_UPDATE_SUCCESS = 'DISCOUNT_PROMOTION_UPDATE_SUCCESS';
export const DISCOUNT_PROMOTION_UPDATE_ERROR = 'DISCOUNT_PROMOTION_UPDATE_ERROR';

export const DISCOUNT_PROMOTION_DELETE = 'DISCOUNT_PROMOTION_DELETE';
export const DISCOUNT_PROMOTION_DELETE_SUCCESS = 'DISCOUNT_PROMOTION_DELETE_SUCCESS';
export const DISCOUNT_PROMOTION_DELETE_ERROR = 'DISCOUNT_PROMOTION_DELETE_ERROR';

export const DISCOUNT_PROMOTION = 'DISCOUNT_PROMOTION';
export const DISCOUNT_PROMOTION_SUCCESS = 'DISCOUNT_PROMOTION_SUCCESS';
export const DISCOUNT_PROMOTION_ERROR = 'DISCOUNT_PROMOTION_ERROR';